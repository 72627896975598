<template>
  <div class="receive">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6 mb-12">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  small
                  dark
                  class="mr-2 btn-prev"
                  @click.prevent="$router.back()"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h1>อัปโหลดสลิป</h1>
              </div>
            </div>
          </v-app-bar>

          <v-container fluid>
            <h2 class="header-receive">
              หมายเลขคำสั่งซื้อ :
              {{ this.$store.getters.getDetailsReceive.orderNo }}
            </h2>
            <h4 class="sub-header-receive">
              วันที่สั่งซื้อ :
              {{
                this.$store.getters.getDetailsReceive.createDate | dateFormat
              }}
            </h4>
            <v-divider class="mt-2 mb-4"></v-divider>
            <!-- <div>
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(item, i) in allOrderPR"
                  :key="i"
                  :disabled="item.purchaseRequisitionStatus == 'DRAFT'"
                >
                  <v-expansion-panel-header>{{
                    item.purchaseOrderResponse.purchaseNo
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content> -->
            <!-- <div class="px-3"> -->
            <div class="ml-3 mt-1">
              <p class="mb-0">
                สถานะ : {{ allOrderPR[0].purchaseOrderResponse.purchaseStatus }}
              </p>
              <!-- <div class="d-flex justify-space-between"> -->
              <p class="mb-0">ยอดชำระ : ฿{{ allOrderPR[0].totalAmount }}</p>
              <!-- <v-btn x-small class="white--text" color="blue">View Gr</v-btn> -->
              <!-- </div> -->
            </div>

            <div class="wrapper-table mb-1 mt-2">
              <table
                class="order-table"
                style="width: 100%; text-align: center"
              >
                <tr class="topic-order">
                  <th>Item</th>
                  <th>Price/Unit</th>
                  <th>Vat</th>
                  <th>Quantity</th>
                  <!-- <th>Receive</th>
                            <th>Action</th> -->
                </tr>
                <tr
                  class="list-menu"
                  v-for="(data, index) in allOrderPR[0].purchaseOrderResponse
                    .details"
                  :key="index"
                >
                  <td>{{ data.itemNameTh }}</td>
                  <td>{{ data.unitPrice }}/{{ data.itemUnit }}</td>
                  <!-- <td>{{ item.quantity }}</td> -->
                  <td>{{ data.vat }}%</td>
                  <td>
                    {{ data.quantity }}
                  </td>
                  <!-- <td>
                              <input
                                autofocus
                                inputmode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                v-model="data.receive"
                                class="input-receive"
                                @change="
                                  inputReceive(
                                    item.id,
                                    index,
                                    data.quantity,
                                    data.receive
                                  )
                                "
                              />
                            </td>
                            <td>
                              <v-btn
                                v-if="!data.cancel"
                                @click.prevent="data.cancel = true"
                                style="width: 20px; height: 19.5px"
                                x-small
                                fab
                                color="red"
                              >
                                <v-icon small color="white">mdi-close</v-icon>
                              </v-btn>
                              <span style="color: red" v-if="data.cancel"
                                >ไม่รับสินค้า</span
                              >
                            </td> -->
                </tr>
              </table>
            </div>
            <!-- <div class="mt-2 text-center">
                      <v-btn
                        small
                        color="primary"
                        @click.prevent="submitReceiveOrder(item.id)"
                        :disabled="
                          idGoodReceipt !== null ||
                          item.purchaseOrderResponse.purchaseStatus ==
                            'COMPLETED'
                        "
                        >ยืนยันการรับสินค้า</v-btn
                      >
                    </div> -->

            <div class="upload mx-4 mt-2">
              <h4 class="mb-2">อัปโหลดหลักฐาน</h4>
              <div>
                <div class="d-flex flex-wrap">
                  <div class="my-2" v-for="(image, key) in images" :key="key">
                    <div class="mr-1">
                      <img
                        width="100px"
                        height="100px"
                        class="preview"
                        :ref="'image'"
                      />
                    </div>
                  </div>
                </div>
                <label class="custom-file-upload">
                  <input
                    type="file"
                    ref="file"
                    name="file"
                    multiple
                    accept="image/*"
                    capture="camera"
                    @change="onFileChange"
                    class="my-2 upload-proof"
                  />
                  <v-icon>mdi-camera</v-icon> อัปโหลดรูป
                </label>
                <!-- <v-file-input
                          show-size
                          type="file"
                            ref="file"
                            name="file"
                            multiple
                            accept="image/*" 
                            capture="camera"
                            @change="onFileChange"
                            class="my-2 upload-proof"
                        ></v-file-input> -->
              </div>
              <!-- <div
                        class="my-2"
                        v-for="(image, key) in images"
                        :key="key"
                      >
                        <div class="d-flex">
                          <img
                            width="100px"
                            height="100px"
                            class="preview"
                            :ref="'image'"
                          />
                          {{ image.name }}
                        </div>
                      </div> -->

              <div class="text-center mt-2">
                <v-btn
                  @click.prevent="saveGoodReceipt(allOrderPR[0].id, 0)"
                  small
                  color="primary"
                  >ยืนยันการชำระเงิน</v-btn
                >
              </div>
            </div>
            <!-- </div> -->
            <!-- </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div> -->
          </v-container>
        </v-card>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

// import VConsole from '@/assets/vconsole.min.js';
// var vConsole = new VConsole();
// console.log('Hello world');

export default {
  name: "Receive",
  data() {
    return {
      allOrderPR: [],
      getIndexGood: null,
      getIndexPR: null,
      getEditQuantityValue: null,
      images: [],
      file: null,
      idGoodReceipt: null,
    };
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm:ss a.");
    },
  },
  created() {
    // let purchaseId = [
    //   ...new Set(
    //     this.$store.getters.getDetailsReceive.details.map(
    //       (item) => item.purchaseId
    //     )
    //   ),
    // ];
    // for (let index = 0; index < purchaseId.length; index++) {
    axios
      .get(
        `/orders/pr/${this.$store.getters.getDetailsReceive.details[0].purchaseId}`
      )
      .then((res) => {
        console.log(res);
        // res.data.purchaseOrderResponse.details.forEach((result) => {
        //   result.receive = result.quantity;
        //   result.cancel = false;
        // });
        // console.log(res.data.purchaseOrderResponse.details);
        // if (res.data.purchaseOrderResponse !== null) {
        this.allOrderPR.push(res.data);
        // this.submitReceiveOrder()
        // }
      });
    // }
    console.log(this.allOrderPR);
  },
  methods: {
    // getCheckStatusPO(id, i) {
    //   axios.get(`/orders/pr/${id}`).then((res) => {
    //     console.log(res.data.purchaseOrderResponse.purchaseStatus);
    //     this.allOrderPR[i].purchaseOrderResponse.purchaseStatus =
    //       res.data.purchaseOrderResponse.purchaseStatus;
    //   });
    // },
    // inputReceive(id, index, quantity, receive) {
    //   console.log(id);
    //   console.log(quantity);
    //   if (receive > quantity) {
    //     let idPr = this.allOrderPR.filter((result) => result.id === id);
    //     idPr[0].purchaseOrderResponse.details[index].receive = quantity;
    //     Swal.fire({
    //       icon: "error",
    //       title: `ไม่สามารถรับเกินจำนวนที่สั่ง`,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   }
    // },
    submitReceiveOrder() {
      //   Swal.fire({
      //   title: "ยืนยันการรับสินค้า?",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: "ยกเลิก",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      // let idPr = this.allOrderPR.filter((result) => result.id === id);
      // console.log(idPr);
      // let data = {
      //   purchaseRequisitionId: this.allOrderPR[0].id,
      //   goods: [],
      // };
      // this.allOrderPR[0].purchaseOrderResponse.details.forEach((res) => {
      //   if (!res.cancel) {
      //     console.log(res);
      //     let good = {
      //       id: res.goodId,
      //       quantity: parseFloat(res.quantity),
      //     };
      //     data.goods.push(good);
      //   }
      // });
      // console.log(data);
      // axios
      //   .post(`/orders/goods-receipt`, data)
      //   .then(async (res) => {
      //     console.log(res.data);
      //     this.idGoodReceipt = res.data.id;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     Swal.fire({
      //       icon: "error",
      //       title: `${err}`,
      //       showConfirmButton: true,
      //     });
      //   });
      //   }
      // })
    },

    saveGoodReceipt(id, i) {
      Swal.fire({
        title: "ยืนยัน?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            purchaseRequisitionId: this.allOrderPR[0].id,
            goods: [],
          };
          this.allOrderPR[0].purchaseOrderResponse.details.forEach((res) => {
            if (!res.cancel) {
              console.log(res);
              let good = {
                id: res.goodId,
                quantity: parseFloat(res.quantity),
              };
              data.goods.push(good);
            }
          });
          console.log(data);
          axios
            .post(`/orders/goods-receipt`, data)
            .then(async (res) => {
              console.log(res.data);
              // this.idGoodReceipt = res.data.id;
              let formData = new FormData();
              for (let indexImage in this.images) {
                formData.append("file", this.images[indexImage]);
              }
              console.log([...formData]);

              axios
                .post(
                  `/orders/goods-receipt/documents/${res.data.id.toString()}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      "Access-Control-Allow-Origin": "*",
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);
                  // this.getCheckStatusPO(id, i);
                  await Swal.fire({
                    icon: "success",
                    title: "สำเร็จ",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  this.images = [];
                  this.$router.back()
                })
                .catch((error) => {
                  console.log(error);
                  // alert(error);
                  Swal.fire({
                    icon: "error",
                    title: `${error}`,
                    showConfirmButton: true,
                  });
                });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: `${err}`,
                showConfirmButton: true,
              });
            });
        }
      });
      //console.log(this.images);
    },

    onFileChange() {
      console.log(this.$refs);
      let fileInput = this.$refs.file.files[0];
      this.file = fileInput;
      this.images.push(this.file);
      //console.log(this.$refs.file[0].files[0]);
      //this.images.push(fileInput)
      //console.log(this.images);
      //       let file = new FormData()
      // file.append("file", fileInput)
      // console.log([...file]);

      // let vm = this;
      // var selectedFiles = e.target.files;
      // for (let i = 0; i < selectedFiles.length; i++) {
      //   //console.log(selectedFiles[i]);
      //   this.images.push(selectedFiles[i]);
      // }
      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.image[i].src = reader.result;
          //console.log(this.$refs.image[i].src);
        };

        reader.readAsDataURL(this.images[i]);
      }

      // console.log(this.images);

      // this.images.push(this.file);
      // let reader = new FileReader();
      // reader.onload = (e) => {
      //   this.$refs.image[0].src = reader.result;
      //   //console.log(this.$refs.image[0]);
      // };
      // reader.readAsDataURL(this.images[0]);
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
.header-receive {
  font-weight: 500;
  font-size: 18px;
  color: #292929;
}
.sub-header-receive {
  font-weight: 400;
  font-size: 14px;
  color: #343434;
}

.table-receive {
  width: 100%;
}
.items-receive {
  width: 100%;
}
.items-receive td {
  width: 50%;
}
.items-receive td:first-child {
  width: 10%;
}
.items-receive td:nth-child(3) {
  width: 24%;
}
.items-receive td:nth-child(4) {
  width: 18%;
}
.items-receive td > input {
  width: 35px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #444;
  outline: none;
  font-size: 14px;
}
.items-receive td:last-child {
  width: 16%;
}
.input-edit-quantity {
  width: 30px;
  margin: 0 auto;
}
.detail-school p {
  font-size: 14px;
}

.wrapper-table {
  position: relative;
  width: 100%;
  overflow: scroll;
}
.order-table {
  border-collapse: collapse;
  border-radius: 3px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.topic-order th {
  font-weight: 500;
  font-size: 11px !important;
  color: #56448d;
  background: #fff;
}
.topic-order th:first-child {
  border-top-left-radius: 3px;
}
.topic-order th:last-child {
  border-top-right-radius: 3px;
}
.list-menu td,
.topic-order th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  transition: 0.4s;
}
.list-menu td {
  padding: 5px 2px;
}

.input-receive {
  width: 30px;
  border: 1px solid #2196f3;
  outline: none;
  border-radius: 4px;
  text-align: center;
  color: #2196f3;
}
.input-receive[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>